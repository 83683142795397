import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import OpeningHours from '../components/OpeningHours'


export const OpeningHoursPageTemplate = ({
  title,
  regularHours,
  deviatingHours,
}) => (
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-12 is-offset-1">
          <div className="section">
            <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="columns">
              <div className="column is-6">
                <OpeningHours deviatingHours={deviatingHours} regularHours={regularHours}></OpeningHours>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

OpeningHoursPageTemplate.propTypes = {
  title: PropTypes.string,
  deviatingHours: PropTypes.array,
  regularHours: PropTypes.array,
}

const OpeningHoursPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout pageTitle={frontmatter.title} pageDescription="Här kan du se ordinarie och avvikande öppetider för Alviks Kontorsmaterial.">
      <OpeningHoursPageTemplate
        title={frontmatter.title}
        regularHours={frontmatter.regularopeninghours}
        deviatingHours={frontmatter.deviatingopeninghours}
      />
    </Layout>
  )
}

OpeningHoursPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default OpeningHoursPage

export const OpeningHoursPageQuery = graphql`
query OpeningHoursPage {
  markdownRemark(frontmatter: {templateKey: {eq: "openinghours-page"}}) {
    frontmatter {
      subheading
      title
      deviatingopeninghours {
        deviatingdate(formatString: "YYYY-MM-DD")
        deviatingopeninghourstext
        name
      }
      regularopeninghours {
        name
        regularopeninghourstext
      }
    }
  }
}
`